<template>
  <div id="genesub">
    <el-card>
      <el-row>
        <el-col :span="6">
          Gene Exploration
          <el-tooltip
            class="item"
            effect="dark"
            content="Check the expression level of the genes you are interested in."
            placement="right-end"
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-select
          style="width: 50%; height: fixed"
          v-model="inputGene"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="Search gene(up to 6)"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in optionss"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button type="info" plain @click="searchGene()">Search</el-button>
      </el-row>
      <el-row :gutter="20" style="margin-top: 50px">
        <el-col :span="24">
          <div v-cloak>
            <h2 style="text-align: center">{{ TissueName }}</h2>
          </div>
          <el-col :span="12">
            <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
              <img
                style="height: 100%;width: auto;"
                class="image2"
                v-bind:src="require('@/assets/integrate/' +TissueName +'_Tissue-sepcific.png')"
              />
            </div>
          </el-col>
          <el-col :span="12">
            <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
              <img
                :loading="umap_load"
                style="max-height: 100%"
                class="image3"
                :src="`data:image/png;base64,${img}`"
              />
            </div>
          </el-col>
        </el-col>
      </el-row>
    </el-card>

    <el-row>
      <el-card>
        <el-row style="margin-bottom: 50px">
          <el-row>
            <el-col :span="6">Comparison</el-col>
          </el-row>
          <br />
          <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="info" plain @click="getViolin()">Update</el-button>
        </el-row>
        <el-row>
          <div id="violin_plt">
            <el-col :span="24">
              <img
                :loading="violin_load"
                v-if="show_gene_violin"
                class="image4"
                :src="`data:image/png;base64,${img_viloin}`"
                style="width: 60%; display: flex; justify-content: center;"
              />
            </el-col>
          </div>
        </el-row>
      </el-card>
    </el-row>
  </div>
</template>

<script>
import { searchGene } from "@/api/getGene.ts";
import { getGene } from "@/api/getGene.ts";
import { getViolin } from "@/api/getGene.ts";
import { BIconLayoutTextWindowReverse } from "bootstrap-vue";
import { Loading } from "element-ui";
export default {
    name: "genesubts",
    data() {
        return {
            TissueName: this.$route.params.tissue,
            optionss: [],
            inputGene: [],
            immune:'',
            list: [],
            loading: false,
            show_gene_violin: false,
            options: [
                {
                    value: "CellType",
                    label: "CellType"
                },
                {
                    value: "Cluster",
                    label: "Cluster"
                }
            ],
            compareType: "CellType"
        }
    },
    methods: {
      
      async getViolin(gene, dataset, compare) {
        this.immune=this.TissueName+'_Tissue-specific'
        console.log('0')
            try {
                let loadingInstance = Loading.service({
                    target: document.getElementById("violin_plt"),
                    fullscreen: true
                });
                console.log('1')               
                this.show_gene_violin = false;
                this.inputGene = this.inputGene.filter(item => {
                    return item.toUpperCase();
                });
                console.log('2')
                const response = await this.axios({
                    method: "post",
                    url: "violin_gene_local/",
                    data: {
                        gene: this.inputGene,
                        dataset: this.immune,
                        compare: this.compareType
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                console.log('3')
                this.img_viloin = response["data"];
                this.show_gene_violin = true;
                loadingInstance.close();
                console.log('4')
            } catch (err) {
                this.$alert("Please try again.", "Gene", {
                    confirmButtonText: "OK",
                    callback: action => {
                        this.$message({
                            type: "info",
                            message: `action: ${action}`
                        });

                    }
                });
            }
        },
      remoteMethod(query) {
            if (query !== "") {
                this.loading = false;
                setTimeout(() => {
                    this.loading = false;
                    this.optionss = this.list.filter(item => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.optionss = this.list.slice(0, 8);
            }
            console.log(this.optionss)
        },
      async getGene(dataset) {
        this.immune=this.TissueName+'_Tissue-specific'
        // console.log(this.immune)
            const response = await this.axios({
                method: "post",
                url: "get_gene/",
                data: {
                    dataset: this.immune
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.available_genes = response["data"];
            this.list = this.available_genes.map(item => {
                return { value: `${item}`, label: `${item}` };
            });
            // console.log(this.available_genes)
            return this.available_genes;
        },
    
    async searchGene(gene, dataset) {
            this.umap_load = true;
            this.immune=this.TissueName+'_Tissue-specific'
            console.log(this.immune)
            this.inputGene = this.inputGene.filter(item => {
                return item.toUpperCase();
            });
            const response = await this.axios({
                method: "post",
                url: "detail_gene_local/",
                data: {
                    gene: this.inputGene,
                    dataset: this.immune,
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.img = response["data"];
            this.show_gene_umap = true;
            this.umap_load = true;
        },
      },
    mounted() {
        this.available_genes = this.getGene();
        this.optionss = this.list.slice(0, 8);
        console.log(this.optionss)

    }
};
</script>
