<template>
    <div id="info">
        <el-tabs>
            <el-tab-pane>
                <span slot="label" class="tab-header">Overview</span>
                <el-row>
                    <el-card>
                        <div id="tiss" type="primary" round>
                            <img height="70px" class="image" v-bind:src="
                            	require('@/assets/tissue/' + input + '.png')
                            " />
                            <p>{{ input }}</p>
                        </div>
                        <el-divider content-position="left"><span style="
									color: rgb(26, 96, 67);
									font-size: 20px; ;
								">Annotation details for different level</span></el-divider>
                        
                            <div id="clu">
                                <div style="width: 24%"  v-if="mid">
                                    <div id="download">
                                        <div id="png1">
                                            <el-image class="image" v-bind:src="
                                            	require('@/assets/integrate/' +
                                            		level1 +
                                            		'_Immune.png')
                                            " :preview-src-list="immu_show_list" style="max-width: 100%; max-height: 100%">
                                            </el-image>
                                        </div>
                                        <p id="bt" @click="dimmu">Export to PDF</p>
                                    </div>

                                </div>
                                <div style="width: 24%" v-if="endo_show">
                                    <div id="download">
                                        <div id="png2">
                                            <el-image class="image" v-bind:src="
                                            	require('@/assets/integrate/' +
                                            		level3 +
                                            		'_Endothelial.png')
                                            " :preview-src-list="endo_show_list" style="max-width: 100%; max-height: 100%">
                                            </el-image>
                                        </div>
                                        <p id="bt" @click="dendo">Export to PDF</p>
                                    </div>

                                </div>
                                <div style="width: 24%" v-if="stromal_show">
                                    <div id="download">
                                        <div id="png3">
                                            <el-image class="image" v-bind:src="
                                            	require('@/assets/integrate/' +
                                            		level3 +
                                            		'_Stromal.png')
                                            " :preview-src-list="stromal_show_list" style="max-width: 100%; max-height: 100%">
                                            </el-image>
                                        </div>
                                        <p id="bt" @click="dstro">Export to PDF</p>
                                    </div>

                                </div>
                                <div style="width: 24%" v-if="ts_show">
                                    <div id="download">
                                        <div id="png4">
                                            <el-image class="image" v-bind:src="
                                            	require('@/assets/integrate/' +
                                            		level3 +
                                            		'_Tissue-specific.png')
                                            " :preview-src-list="ts_show_list" style="max-width: 100%; max-height: 100%">
                                            </el-image>
                                        </div>
                                        <p id="bt" @click="dts">Export to PDF</p>
                                    </div>

                                </div>
                            </div>
                        
                    </el-card>
                    <!-- <el-card>
                        <el-divider content-position="left"><span
                                style="color: rgb(26, 96, 67); font-size: 30px">3D-UMAP</span>
                        </el-divider>
                        <div>
                            <el-radio v-model="radio" label="1">Immune</el-radio>
                            <el-radio v-model="radio" label="2" v-if="endo_show">Endothelial</el-radio>
                            <el-radio v-model="radio" label="3" v-if="stromal_show">Stromal</el-radio>
                            <el-radio v-model="radio" label="4" v-if="ts_show">Tissue-specific</el-radio>
                        </div>
                        <div>
                            <iframe id="iframe" v-bind:src="immune_url" frameborder="0" v-if="radio==1"></iframe>
                            <iframe id="iframe" v-bind:src="endo_url" frameborder="0" v-if="radio==2"></iframe>
                            <iframe id="iframe" v-bind:src="stromal_url" frameborder="0" v-if="radio==3"></iframe>
                            <iframe id="iframe" v-bind:src="ts_url" frameborder="0" v-if="radio==4"></iframe>
                        </div>
                    </el-card> -->
                    <el-card>
                        <el-row>
                            <div style="margin-top: 20px">
                                <el-divider content-position="left"><span
                                        style="color: rgb(26, 96, 67); font-size: 30px">Marker</span>
                                </el-divider>
                                <el-row>
                                    <template>
                                        <el-table :data="mkData" stripe style="width: 80%" height="300">
                                            <el-table-column prop="celltype" label="Celltye">
                                            </el-table-column>
                                            <el-table-column prop="marker" label="Marker">
                                            </el-table-column>
                                        </el-table>
                                    </template>
                                </el-row>
                            </div>
                        </el-row>
                    </el-card>
                </el-row>

                <div id="gene">
                    <el-card>
                        <el-divider content-position="left"><span
                                style="color: rgb(26, 96, 67); font-size: 30px">Exploration within tissue</span>
                        </el-divider>
                        <el-row :gutter="20" style="margin-top: 50px"> </el-row>
                        <el-row :gutter="20">
                            <div style="margin-top: 15px">
                                <el-input placeholder="Input gene" v-model="inputGene" class="input-with-select"
                                    ref="mark" @change="isShow" style="width: 400px">
                                    <el-button icon="el-icon-search" slot="append" @click="getHeatmap()"></el-button>
                                </el-input>
                            </div>
                        </el-row>

                        <el-row :gutter="20" style="margin-top: 50px">
                            <el-col :span="24">
                                <div v-if="show_gene_heatmap">
                                    <el-col :span="12">
                                        <div>
                                            <div>

                                            </div>
                                        </div>
                                        <img class="image3" :src="`data:image/png;base64,${img_heatmap}`" />
                                    </el-col>
                                </div>
                            </el-col>
                        </el-row>
                    </el-card>
                </div>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label" class="tab-header">Dataset</span>
                <el-row>
                    <el-col>
                        <div class="grid-content bg-purple-dark"></div>
                        <el-card class="box-card">
                            <el-table v-model="input" :data="
                            	data_list
                            		.filter(
                            			(data) =>
                            				!input ||
                            				data.Tissue.toLowerCase().includes(
                            					input.toLowerCase()
                            				) ||
                            				data.Dataset.toLowerCase().includes(
                            					input.toLowerCase()
                            				)
                            		)
                            		.slice(
                            			(currentPage - 1) * pageSize,
                            			currentPage * pageSize
                            		)
                            " stripe border :default-sort="{
                            	prop: 'Tissue',
                            	order: 'ascending',
                            }" @sort-change="sortChange" :row-class-name="tableRowClassName" @row-click="onRowClick">
                                <template slot="empty">
                                    <div class="noData">No Results :(</div>
                                </template>

                                <el-table-column type="index" label="#"></el-table-column>
                                <el-table-column prop="Tissue" label="Tissue" align="center" min-width="15%" sortable>
                                </el-table-column>
                                <el-table-column prop="Dataset" label="Dataset" align="center" min-width="30%" @click=""
                                    sortable>
                                    <template slot-scope="scope">
                                        <el-popover placement="right" width="200" trigger="hover">
                                            <span>
                                                <img :src="
                                                	require(`@/assets/pictures/cluster/${scope.row.Dataset}_cluster.png`)
                                                " style="
														max-height: 500px;
														max-width: 500px;
													" />
                                                <img :src="
                                                	require(`@/assets/pictures/assign/${scope.row.Dataset}_assign.png`)
                                                " style="
														max-height: 500px;
														max-width: 500px;
													" />
                                            </span>
                                            <div slot="reference">
                                                {{ scope.row.Dataset }}
                                            </div>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Year" label="Year" align="center" min-width="10%"
                                    sortable="custom">
                                </el-table-column>
                                <el-table-column prop="PMID" label="PMID" align="center" min-width="10%"
                                    sortable="custom">
                                    <template slot-scope="scope">
                                        <a class="doclink" :href="scope.row.PMID" target="_blank">{{ scope.row.PMIDid
                                        }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="GSE" label="Source" align="center" min-width="15%"
                                    sortable="custom">
                                    <template slot-scope="scope">
                                        <a :href="scope.row.GSE" target="_blank" class="doclink">{{ scope.row.Source
                                        }}</a>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="Stage" label="Stage" align="center" min-width="10%"
                                    sortable="custom">
                                </el-table-column>
                                <el-table-column prop="Platform" label="Platform" align="center" min-width="15%"
                                    sortable="custom">
                                </el-table-column>
                                <el-table-column prop="CellNumber" label="CellNumber" align="center" min-width="15%"
                                    sortable="custom">
                                </el-table-column>
                            </el-table>

                            <div class="block" style="text-align: center">
                                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                    :current-page.sync="currentPage" :page-size="10" layout="prev, pager, next, jumper"
                                    :total="
                                    	data_list.filter(
                                    		(data) =>
                                    			!input ||
                                    			data.Tissue.includes(input)
                                    	).length
                                    ">
                                </el-pagination>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label" class="tab-header">Gene</span>
                <el-divider content-position="left"><span style="
                        color: rgb(26, 96, 67);
                        font-size: 20px; ;
                    ">Select the level</span></el-divider>
                <br>
                <div>
                    <el-radio v-model="radio4" label="1" v-if="mid" @click="no_umap">Immune</el-radio>
                    <el-radio v-model="radio4" label="2" v-if="endo_show" @click="no_umap">Endothelial</el-radio>
                    <el-radio v-model="radio4" label="3" v-if="stromal_show" @click="no_umap">Stromal</el-radio>
                    <el-radio v-model="radio4" label="4" v-if="ts_show" @click="no_umap">Tissue-specific</el-radio>
                </div>
                <br>
                <br>
                <div v-if="radio4==1">
                    <!-- <p>hahahahaha</p> -->
                    <!-- <genesubimu></genesubimu> -->
                    <!-- <router-view></router-view> -->
                    <div id="genesub">
                        <el-card>
                            <el-row>
                                <el-col :span="6">
                                    Gene Exploration
                                    <el-tooltip class="item" effect="dark"
                                        content="Check the expression level of the genes you are interested in."
                                        placement="right-end">
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable
                                    remote reserve-keyword placeholder="Search gene(up to 6)"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in optionss" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-button type="info" plain @click="searchGene()">Search</el-button>
                            </el-row>
                            <el-row :gutter="20" style="margin-top: 50px">
                                <el-col :span="24">
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ TissueName }}</h2>
                                    </div>
                                    <el-col :span="12">
                                        <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                            <img style="height: 100%;width: auto;" class="image2"
                                                v-bind:src="require('@/assets/integrate/' +TissueName +'_Immune.png')" />
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div id="umap_plt">
                                            <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                            <img :loading="umap_load" v-if="show_gene_umap" style="max-height: 100%" class="image3"
                                                :src="`data:image/png;base64,${img}`" />
                                        </div>
                                        </div>
                                        
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-row>
                            <el-card>
                                <el-row style="margin-bottom: 50px">
                                    <el-row>
                                        <el-col :span="6">Comparison</el-col>
                                    </el-row>
                                    <br />
                                    <el-select v-model="compareType" placeholder="Celltype" value-key="value">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-button type="info" plain @click="getViolin()">Update</el-button>
                                </el-row>
                                <el-row>
                                    <div id="violin_plt">
                                        <el-col :span="24">
                                            <div id="'download">
                                                <div id="png5">
                                                    <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                        :src="`data:image/png;base64,${img_viloin}`"
                                                        style="width: 60%; display: flex; justify-content: center;" />
                                                </div>
                                                <p id="bt" @click="dv" v-if="show_gene_violin">Export to PDF</p>
                                            </div>

                                        </el-col>
                                    </div>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>
                </div>
                <div v-if="radio4==2">
                    <div id="genesub">
                        <el-card>
                            <el-row>
                                <el-col :span="6">
                                    Gene Exploration
                                    <el-tooltip class="item" effect="dark"
                                        content="Check the expression level of the genes you are interested in."
                                        placement="right-end">
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable
                                    remote reserve-keyword placeholder="Search gene(up to 6)"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in optionss" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-button type="info" plain @click="searchGene()">Search</el-button>
                            </el-row>
                            <el-row :gutter="20" style="margin-top: 50px">
                                <el-col :span="24">
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ TissueName }}</h2>
                                    </div>
                                    <el-col :span="12">
                                        <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                            <img style="height: 100%;width: auto;" class="image2"
                                                v-bind:src="require('@/assets/integrate/' +TissueName +'_Endothelial.png')" />
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div id="umap_plt">
                                            <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                            <img :loading="umap_load" v-if="show_gene_umap" style="max-height: 100%" class="image3"
                                                :src="`data:image/png;base64,${img}`" />
                                        </div>
                                        </div>
                                       
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-row>
                            <el-card>
                                <el-row style="margin-bottom: 50px">
                                    <el-row>
                                        <el-col :span="6">Comparison</el-col>
                                    </el-row>
                                    <br />
                                    <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-button type="info" plain @click="getViolin()">Update</el-button>
                                </el-row>
                                <el-row>
                                    <div id="violin_plt">
                                        <el-col :span="24">
                                            <div id="download">
                                                <div id="png5">
                                                    <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                        :src="`data:image/png;base64,${img_viloin}`"
                                                        style="width: 60%; display: flex; justify-content: center;" />

                                                </div>
                                                <p id="bt" @click="dv" v-if="show_gene_violin">Export to PDF</p>
                                            </div>

                                        </el-col>
                                    </div>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>

                </div>
                <div v-if="radio4==3">
                    <div id="genesub">
                        <el-card>
                            <el-row>
                                <el-col :span="6">
                                    Gene Exploration
                                    <el-tooltip class="item" effect="dark"
                                        content="Check the expression level of the genes you are interested in."
                                        placement="right-end">
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable
                                    remote reserve-keyword placeholder="Search gene(up to 6)"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in optionss" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-button type="info" plain @click="searchGene()">Search</el-button>
                            </el-row>
                            <el-row :gutter="20" style="margin-top: 50px">
                                <el-col :span="24">
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ TissueName }}</h2>
                                    </div>
                                    <el-col :span="12">
                                        <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                            <img style="height: 100%;width: auto;" class="image2"
                                                v-bind:src="require('@/assets/integrate/' +TissueName +'_Stromal.png')" />
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div id="umap_plt">
                                            <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                            <img :loading="umap_load" v-if="show_gene_umap" style="max-height: 100%" class="image3"
                                                :src="`data:image/png;base64,${img}`" />
                                        </div>
                                        </div>
                                        
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-row>
                            <el-card>
                                <el-row style="margin-bottom: 50px">
                                    <el-row>
                                        <el-col :span="6">Comparison</el-col>
                                    </el-row>
                                    <br />
                                    <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-button type="info" plain @click="getViolin()">Update</el-button>
                                </el-row>
                                <el-row>
                                    <div id="violin_plt">
                                        <el-col :span="24">
                                            <div id="download">
                                                <div id="png5">
                                                    <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                        :src="`data:image/png;base64,${img_viloin}`"
                                                        style="width: 60%; display: flex; justify-content: center;" />

                                                </div>
                                                <p id="bt" @click="dv" v-if="show_gene_violin">Export to PDF</p>
                                            </div>

                                        </el-col>
                                    </div>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>

                </div>
                <div v-if="radio4==4">
                    <div id="genesub">
                        <el-card>
                            <el-row>
                                <el-col :span="6">
                                    Gene Exploration
                                    <el-tooltip class="item" effect="dark"
                                        content="Check the expression level of the genes you are interested in."
                                        placement="right-end">
                                        <i class="el-icon-info"></i>
                                    </el-tooltip>
                                </el-col>
                            </el-row>
                            <br />
                            <el-row>
                                <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable
                                    remote reserve-keyword placeholder="Search gene(up to 6)"
                                    :remote-method="remoteMethod" :loading="loading">
                                    <el-option v-for="item in optionss" :key="item.value" :label="item.label"
                                        :value="item.value"></el-option>
                                </el-select>
                                <el-button type="info" plain @click="searchGene()">Search</el-button>
                            </el-row>
                            <el-row :gutter="20" style="margin-top: 50px">
                                <el-col :span="24">
                                    <div v-cloak>
                                        <h2 style="text-align: center">{{ TissueName }}</h2>
                                    </div>
                                    <el-col :span="12">
                                        <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                            <img style="height: 100%;width: auto;" class="image2"
                                                v-bind:src="require('@/assets/integrate/' +TissueName +'_Tissue-specific.png')" />
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div id="umap_plt">
                                            <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                            <img :loading="umap_load" v-if="show_gene_umap" style="max-height: 100%" class="image3"
                                                :src="`data:image/png;base64,${img}`" />
                                        </div>
                                        </div>
                                        
                                    </el-col>
                                </el-col>
                            </el-row>
                        </el-card>

                        <el-row>
                            <el-card>
                                <el-row style="margin-bottom: 50px">
                                    <el-row>
                                        <el-col :span="6">Comparison</el-col>
                                    </el-row>
                                    <br />
                                    <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
                                        <el-option v-for="item in options" :key="item.value" :label="item.label"
                                            :value="item.value"></el-option>
                                    </el-select>
                                    <el-button type="info" plain @click="getViolin()">Update</el-button>
                                </el-row>
                                <el-row>
                                    <div id="violin_plt">
                                        <el-col :span="24">
                                            <div id="download">
                                                <div id="png5">
                                                    <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                        :src="`data:image/png;base64,${img_viloin}`"
                                                        style="width: 60%; display: flex; justify-content: center;" />
                                                </div>
                                                <p id="bt" @click="dv" v-if="show_gene_violin">Export to PDF</p>
                                            </div>

                                        </el-col>
                                    </div>
                                </el-row>
                            </el-card>
                        </el-row>
                    </div>

                </div>










            </el-tab-pane>
            <!-- <el-tab-pane>
                                <span slot="label" class="tab-header">Gene</span>
                                <el-card>
                                    <el-row>
                                        <el-col :span="6">
                                            Gene Exploration
                                            <el-tooltip class="item" effect="dark"
                                                content="Check the expression level of the genes you are interested in." placement="right-end">
                                                <i class="el-icon-info"></i>
                                            </el-tooltip>
                                        </el-col>
                                    </el-row>
                                    <br />
                                    <el-row>
                                        <el-select style="width: 50%; height: fixed" v-model="inputGene" multiple filterable remote reserve-keyword
                                            placeholder="Search gene(up to 6)" :remote-method="remoteMethod" :loading="loading">
                                            <el-option v-for="item in optionss" :key="item.value" :label="item.label" :value="item.value">
                                            </el-option>
                                        </el-select>
                                        <el-button type="info" plain @click="searchGene()">Search</el-button>
                                    </el-row>
                                    <el-row :gutter="20" style="margin-top: 50px">
                                        <el-col :span="24">
                                            <div v-cloak>
                                                <h2 style="text-align: center">{{ DatasetName }}</h2>
                                            </div>
                                            <el-col :span="12">
                                                <div ref="plt1" class="plt1" id="plt1" style="height: 400px">
                                                    <img style="
                            													height: 100%;
                            													width: auto;
                            												" class="image2" v-bind:src="
                            												    require('@/assets/pictures/assign/' +
                            												        DatasetName +
                            												        '_assign.png')
                            												" />
                                                </div>
                                            </el-col>
                                            <el-col :span="12">
                                                <div id="imgdiv" class="scrollable" v-if="show_gene_umap" style="height: 400px">
                                                    <img :loading="umap_load" style="max-height: 100%" class="image3"
                                                        :src="`data:image/png;base64,${img}`" />
                                                </div>
                                            </el-col>
                                        </el-col>
                                    </el-row>
                                </el-card>
                            
                                <el-row>
                                    <el-card>
                                        <el-row style="margin-bottom: 50px">
                                            <el-row>
                                                <el-col :span="6">Comparison</el-col>
                                            </el-row>
                                            <br />
                                            <el-select v-model="compareType" placeholder="Celltype/Cluster" value-key="value">
                                                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                                                </el-option>
                                            </el-select>
                                            <el-button type="info" plain @click="getViolin()">Update</el-button>
                                        </el-row>
                                        <el-row>
                                            <div id="violin_plt">
                                                <el-col :span="24">
                                                    <img :loading="violin_load" v-if="show_gene_violin" class="image4"
                                                        :src="`data:image/png;base64,${img_viloin}`"
                                                        style="width: 60%; display: flex; justify-content: center;" />
                                                </el-col>
                                            </div>
                                        </el-row>
                                    </el-card>
                                </el-row>
                            </el-tab-pane> -->

            <el-tab-pane>
                <span slot="label" class="tab-header">GSEA</span>
                <el-card>
                    <el-divider content-position="left"><span style="
                        color: rgb(26, 96, 67);
                        font-size: 20px; ;
                    ">Select the level</span></el-divider>
                    <br>
                    <div>
                        <el-radio v-model="radio" label="1" v-if="mid">Immune</el-radio>
                        <el-radio v-model="radio" label="2" v-if="endo_show">Endothelial</el-radio>
                        <el-radio v-model="radio" label="3" v-if="stromal_show">Stromal</el-radio>
                        <el-radio v-model="radio" label="4" v-if="ts_show">Tissue-specific</el-radio>
                    </div>
                    <br>
                    <br>

                    <el-tabs v-if="radio==1">
                        <el-tab-pane>
                            <span slot="label" class="tab-header">KEGG</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png6">
                                        <el-image v-bind:src="
                                            require('@/assets/pictures/GSEA_plot/' +
                                                level1 +
                                                '_Immune_kegg_UP_heatmap.png')
                                        " :preview-src-list="[
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_DOWN_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png7">
                                        <el-image class="image" v-bind:src="
                                            require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                                '_Immune_kegg_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_DOWN_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label" class="tab-header">Hallmark</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png8">
                                        <el-image class="image" v-bind:src="
                                            require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                                '_Immune_hallmark_UP_heatmap.png')
                                        " :preview-src-list="[
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_DOWN_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png9">
                                        <el-image class="image" v-bind:src="
                                            require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                                '_Immune_hallmark_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_DOWN_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_hallmark_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_UP_heatmap.png'),
                                    require('@/assets/pictures/GSEA_plot/' +
                                    level1 +
                                        '_Immune_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <el-tabs v-if="radio==2">
                        <el-tab-pane>
                            <span slot="label" class="tab-header">KEGG</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png6">
                                        <el-image v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                            '_Endothelial_kegg_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png7">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Endothelial_kegg_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label" class="tab-header">Hallmark</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png8">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Endothelial_hallmark_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png9">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Endothelial_hallmark_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Endothelial_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <el-tabs v-if="radio==3">
                        <el-tab-pane>
                            <span slot="label" class="tab-header">KEGG</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png6">
                                        <el-image v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                            '_Stromal_kegg_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png7">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Stromal_kegg_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label" class="tab-header">Hallmark</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png8">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Stromal_hallmark_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png9">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Stromal_hallmark_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Stromal_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <el-tabs v-if="radio==4">
                        <el-tab-pane>
                            <span slot="label" class="tab-header">KEGG</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png6">
                                        <el-image v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                            level1 +
                                            '_Tissue-specific_kegg_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    KEGG PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png7">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Tissue-specific_kegg_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dk2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label" class="tab-header">Hallmark</span>
                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    UP-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png8">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Tissue-specific_hallmark_UP_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh1">Export to PDF</p>
                                </div>
                            </div>

                            <div id="gsea_plt">
                                <span style="color: rgb(26, 96, 67) ;font-size: 20px;text-align: center;display:block;">
                                    DOWN-REGULATED
                                    HALLMARK PATHWAYS
                                </span>
                                <div id="download">
                                    <div id="png9">
                                        <el-image class="image" v-bind:src="
                                        require('@/assets/pictures/GSEA_plot/' +
                                        level1 +
                                            '_Tissue-specific_hallmark_DOWN_heatmap.png')
                                        " :preview-src-list="[
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_DOWN_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_hallmark_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_UP_heatmap.png'),
                                require('@/assets/pictures/GSEA_plot/' +
                                level1 +
                                    '_Tissue-specific_kegg_DOWN_heatmap.png'),
                                ]"></el-image>
                                    </div>
                                    <p id="bt" @click="dh2">Export to PDF</p>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </el-card>
            </el-tab-pane>

            <el-tab-pane>
                <span slot="label" class="tab-header">Cell-Cell Interaction</span>
                <el-card>
                    <el-divider content-position="left"><span style="
                        color: rgb(26, 96, 67);
                        font-size: 20px; ;
                    ">Select the level</span></el-divider>
                    <br>
                    <div>
                        <el-radio v-model="radio2" label="1" v-if="mid">Immune</el-radio>
                        <el-radio v-model="radio2" label="2" v-if="endo_show">Endothelial</el-radio>
                        <el-radio v-model="radio2" label="3" v-if="stromal_show">Stromal</el-radio>
                        <el-radio v-model="radio2" label="4" v-if="ts_show">Tissue-specific</el-radio>
                    </div>
                    <br>
                    <br>
                    <div v-if="radio2==1">
                        <el-card>
                            <div id="cc">
                                <el-row  type="flex" align="middle" >
                                <el-col :span="12">
                                    <div id="download">
                                        <div id="png10">
                                            <img class="image" style="width: 90%" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/integrate/' +
                                                    level1 +
                                                    '_Immune_Interaction.png')
                                            " />
                                        </div>
                                        <!-- <p id="bt" @click="dccih">Export to PDF</p> -->
                                    </div>
                                </el-col>
                                <el-col :span="12">

                                    <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltUrl">
                                        <el-option v-for="item in Cluster_Immune" :key="item" :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                    <div v-if="tmp_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +
                                                        level1 +
                                                        '_Immune_C' +
                                                        tmp +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                    <div v-if="plt_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +level1+'_Immune_C'+
                                                        clu +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            </div>
                            <br>
                            <div id="sxy">
                            <div>
                                <p id="bt" @click="dccih">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dccic">Export to PDF</p>
                            </div>
                        </div>
                        </el-card>
                        <div v-if="plt_show" id="ccc">
                            <br />

                            <el-col :span="12">
                                <div id="download">


                                    <div id="png12">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Immune_' +
                                                clu +
                                                '_in.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciin">Export to PDF</p>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png13">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Immune_' +
                                                clu +
                                                '_out.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciout">Export to PDF</p>
                                </div>
                            </el-col>
                        </div>


                    </div>
                    <div v-if="radio2==2">
                        <el-card>
                            <div id="cc">
                                <el-row  type="flex" align="middle" >
                                <el-col :span="12">
                                    <div id="download">
                                        <div id="png10">
                                            <img class="image" style="width: 90%" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/integrate/' +
                                                    level1 +
                                                    '_Endothelial_Interaction.png')
                                            " />
                                        </div>
                                        <!-- <p id="bt" @click="dccih">Export to PDF</p> -->
                                    </div>
                                </el-col>
                                <el-col :span="12">

                                    <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltUrl">
                                        <el-option v-for="item in Cluster_Immune" :key="item" :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                    <div v-if="tmp_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +
                                                        level1 +
                                                        '_Endothelial_C' +
                                                        tmp +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                    <div v-if="plt_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +level1+'_Endothelial_C'+
                                                        clu +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            </div>
                            <br>
                            <div id="sxy">
                            <div>
                                <p id="bt" @click="dccih">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dccic">Export to PDF</p>
                            </div>
                        </div>
                        </el-card>
                        <div v-if="plt_show" id="ccc">
                            <br />

                            <el-col :span="12">
                                <div id="download">


                                    <div id="png12">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Endothelial_' +
                                                clu +
                                                '_in.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciin">Export to PDF</p>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png13">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Endothelial_' +
                                                clu +
                                                '_out.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciout">Export to PDF</p>
                                </div>
                            </el-col>
                        </div>


                    </div>
                    <div v-if="radio2==3">
                        <el-card>
                            <div id="cc">
                                <el-row  type="flex" align="middle" >
                                <el-col :span="12">
                                    <div id="download">


                                        <div id="png10">
                                            <img class="image" style="width: 90%" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/integrate/' +
                                                    level1 +
                                                    '_Stromal_Interaction.png')
                                            " />
                                        </div>
                                        <!-- <p id="bt" @click="dccih">Export to PDF</p> -->
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltUrl">
                                        <el-option v-for="item in Cluster_Stromal" :key="item" :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                    <div v-if="tmp_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +
                                                        level1 +
                                                        '_Stromal_C' +
                                                        tmp +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                    <div v-if="plt_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +level1+'_Stromal_C'+
                                                        clu +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            </div>
                            <br>
                            <div id="sxy">
                            <div>
                                <p id="bt" @click="dccih">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dccic">Export to PDF</p>
                            </div>
                        </div>
                        </el-card>
                        <div v-if="plt_show" id="cc">
                            <br />
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png12">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Stromal_' +
                                                clu +
                                                '_in.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciin">Export to PDF</p>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png13">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Stromal_' +
                                                clu +
                                                '_out.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciout">Export to PDF</p>
                                </div>
                            </el-col>
                        </div>

                    </div>
                    <div v-if="radio2==4">
                        <el-card>
                            <div id="cc">
                                <el-row type="flex" align="middle">
                                <el-col :span="12">
                                    <div id="download">


                                        <div id="png10">
                                            <img class="image" style="width: 90%" v-bind:src="
                                                require('@/assets/pictures/CCI_PLT/integrate/' +
                                                    level1 +
                                                    '_Tissue-specific_Interaction.png')
                                            " />
                                        </div>
                                        <!-- <p id="bt" @click="dccih">Export to PDF</p> -->
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltUrl">
                                        <el-option v-for="item in Cluster_TS" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>
                                    <div v-if="tmp_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +
                                                        level1 +
                                                        '_Tissue-specific_C' +
                                                        tmp +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <!-- <p id="bt" @click="dccic">Export to PDF</p> -->
                                        </div>
                                    </div>
                                    <div v-if="plt_show">
                                        <div id="download">
                                            <div id="png11">
                                                <img class="image" style="width: 90%" v-bind:src="
                                                    require('@/assets/pictures/CCI_PLT/integrate/' +level1+'_Tissue-specific_C'+
                                                        clu +
                                                        '_circle.png')
                                                " />
                                            </div>
                                            <p id="bt" @click="dccic">Export to PDF</p>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                            </div>
                            <br>
                            <div id="sxy">
                            <div>
                                <p id="bt" @click="dccih">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dccic">Export to PDF</p>
                            </div>
                        </div>
                        </el-card>
                        <div v-if="plt_show" id="cc">
                            <br />
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png12">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Tissue-specific_' +
                                                clu +
                                                '_in.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciin">Export to PDF</p>
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <div id="download">


                                    <div id="png13">
                                        <img class="image" style="width: 90%" v-bind:src="
                                            require('@/assets/pictures/CCI_PLT/integrate/' +
                                                level1 +
                                                '_Tissue-specific_' +
                                                clu +
                                                '_out.png')
                                        " />
                                    </div>
                                    <p id="bt" @click="dcciout">Export to PDF</p>
                                </div>
                            </el-col>
                        </div>
                    </div>

                </el-card>
            </el-tab-pane>
            <el-tab-pane>
                <span slot="label" class="tab-header">TF</span>

                <el-divider content-position="left"><span style="
                        color: rgb(26, 96, 67);
                        font-size: 20px; 
                    ">Select the level</span></el-divider>
                <br>
                <div>
                    <el-radio v-model="radio3" label="1" v-if="mid">Immune</el-radio>
                    <el-radio v-model="radio3" label="2" v-if="endo_show">Endothelial</el-radio>
                    <el-radio v-model="radio3" label="3" v-if="stromal_show">Stromal</el-radio>
                    <el-radio v-model="radio3" label="4" v-if="ts_show">Tissue-specific</el-radio>

                </div>
                <br>
                <br>

                <div v-if="radio3==1">
                    <el-card>
                        <div id="end">
                            <el-row  type="flex" align="middle" >
                            <el-col :span="12">
                                <div id="download">
                                    <div id="png14">
                                        <img class="image" style="width: 90%; display: flex; justify-content: center;"
                                            v-bind:src="require('../assets/pictures/TFheatmap/integrate/' +
                                                level1 + '_Immune_TFheatmap.png')
                                            " />
                                    </div>
                                    <!-- <p id="bt" @click="dtfh">Export to PDF</p> -->
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltDot">
                                    <el-option v-for="item in Cluster_Immune" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                                <div v-if="tmp_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Immune/' + 
                                                        'C' +
                                                        tmp +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                                <div v-if="plt_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Immune/C' + 
                                                        clu +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        </div>
                        <br>
                        <div id="sxy">
                            <div>
                                <p id="bt" @click="dtfh">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dtfc">Export to PDF</p>
                            </div>
                        </div>
                    </el-card>
                </div>
                <div v-if="radio3==2">
                    <el-card>
                        <div id="end">
                            <el-row  type="flex" align="middle" >
                            <el-col :span="12">
                                <div id="download">
                                    <div id="png14">
                                        <img class="image" style="width: 90%; display: flex; justify-content: center;"
                                            v-bind:src="require('../assets/pictures/TFheatmap/integrate/' +
                                                level1 + '_Endothelial_TFheatmap.png')
                                            " />
                                    </div>
                                    <!-- <p id="bt" @click="dtfh">Export to PDF</p> -->
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltDot">
                                    <el-option v-for="item in Cluster_Endothelial" :key="item" :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                                <div v-if="tmp_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Endothelial/' +
                                                        'C' +
                                                        tmp +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                                <div v-if="plt_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Endothelial/c' + 
                                                        clu +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        </div>
                        <br>
                        <div id="sxy">
                            <div>
                                <p id="bt" @click="dtfh">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dtfc">Export to PDF</p>
                            </div>
                        </div>
                    </el-card>
                </div>
                <div v-if="radio3==3" id="end">
                    <el-card>
                        <div>
                            <el-row  type="flex" align="middle" >
                            <el-col :span="12">
                                <div id="download">
                                    <div id="png14">
                                        <img class="image" style="width: 100%; display: flex; justify-content: center;"
                                            v-bind:src="require('../assets/pictures/TFheatmap/integrate/' +
                                                level1 + '_Stromal_TFheatmap.png')
                                            " />
                                    </div>

                                    <!-- <p id="bt" @click="dtfh">Export to PDF</p> -->
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltDot">
                                    <el-option v-for="item in Cluster_Stromal" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                                <div v-if="tmp_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Stromal/' + 
                                                        'C' +
                                                        tmp +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                                <div v-if="plt_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Stromal/C' + 
                                                        clu +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        </div>
                        <br>
                        <div id="sxy">
                            <div>
                                <p id="bt" @click="dtfh">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dtfc">Export to PDF</p>
                            </div>
                        </div>
                    </el-card>
                </div>
                <div v-if="radio3==4" id="end">
                    <el-card>
                        <div>
                            <el-row  type="flex" align="middle" align="middle">
                            <el-col :span="12">
                                <div id="download">
                                    <div id="png14">
                                        <img class="image" style="width: 100%; display: flex; justify-content: center;"
                                            v-bind:src="require('../assets/pictures/TFheatmap/integrate/' +
                                                level1 + '_Tissue-specific_TFheatmap.png')
                                            " />
                                    </div>

                                    <!-- <p id="bt" @click="dtfh">Export to PDF</p> -->
                                </div>
                            </el-col>
                            <el-col :span="12">
                                <el-select v-model="clu" placeholder="Choose a cluster" @change="getPltDot">
                                    <el-option v-for="item in Cluster_TS" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                                <div v-if="tmp_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Tissue-specific/' + 
                                                        'C' +
                                                        tmp +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                                <div v-if="plt_show1">
                                    <div id="download">
                                        <div id="png15">
                                            <img class="image"
                                                style="width: 90%; display: flex; justify-content: center;" v-bind:src="
                                                    require('@/assets/pictures/TFdotplot/' +
                                                        level1 + '_Tissue-specific/C' + 
                                                        clu +
                                                        '_TF.png')
                                                " />
                                        </div>
                                        <!-- <p id="bt" @click="dtfc">Export to PDF</p> -->
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                        </div>
                        <br>
                        <div id="sxy">
                            <div>
                                <p id="bt" @click="dtfh">Export to PDF</p>
                            </div>
                            <div>
                                <p id="bt" @click="dtfc">Export to PDF</p>
                            </div>
                        </div>
                    </el-card>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import axios from "axios";
import data_json from "@/assets/data_1011.json";
import marker_json from "@/assets/HUSCH_marker_merge.json";
import genesubimu from "@/components/integrate/geneimu.vue";
import genesubts from "@/components/integrate/genets.vue";
import genesubendo from "@/components/integrate/geneendo.vue";
import genesubstro from "@/components/integrate/genestro.vue";
// import { threadId } from "worker_threads";
import CCI_Cluster from "@/assets/summary_getccijson.json";
import { searchGene } from "@/api/getGene.ts";
import { getGene } from "@/api/getGene.ts";
import { getViolin } from "@/api/getGene.ts";
import { BIconLayoutTextWindowReverse } from "bootstrap-vue";
import { Loading } from "element-ui";
import html2pdf from "html2pdf.js";

export default {
    name: "info",
    data() {
        return {
            mid: true,
            itemList: [],
            data_list: data_json,
            res: "",
            input: this.$route.params.tissue,
            input_tissue: "",
            hover: false,
            DatasetName: "",
            currentPage: 1,
            pageSize: 10,
            isRouterAlive: true,
            level1: "",
            radio: '1',
            radio2: '1',
            radio3: '1',
            radio4: '1',
            TissueName: this.$route.params.tissue,
            optionss: [],
            inputGene: [],
            immune: '',
            list: [],
            loading: false,
            show_gene_violin: false,
            options: [
                {
                    value: "CellType",
                    label: "CellType"
                }
                // ,
                // {
                //     value: "Cluster",
                //     label: "Cluster"
                // }
            ],
            compareType: "CellType",
            level3: "",

            ts: ["Heart", "Lymph-Node", "Blood", 'Bone-Marrow', 'Spleen', 'Cerebrospinal-Fluid'],
            ts_show: true,
            endo: [
                "Nose",
                "Pleura",
                "Lymph-Node",
                "Blood",
                'Bone-Marrow',
                'Cerebrospinal-Fluid',
                'Ureter',
            ],
            endo_show: true,
            stromal: [
                "Tonsil",
                "Ear",
                "Lymph-Node",
                "Blood",
                'Bone-Marrow',
                'Spleen',
                'Cerebrospinal-Fluid'
            ],
            im:["Midbrain","Ureter"],
            im2:["Ureter"],
            stromal_show: true,
            inputGene: "",
            select: "",
            immune_url: "",
            endo_url: "",
            ts_url: "",
            stromal_url: "",
            show_gene_heatmap: false,
            tiss_list: [
                "Adipose",
                "Adrenal-Gland",
                "Airway",
                "Artery",
                "Bladder",
                "Blood",
                "Bone",
                "Bone-Marrow",
                "Breast",
                "Cerebellum",
                "Cerebral-Cortex",
                "Cerebrospinal-Fluid",
                "Cervix",
                "Choroid",
                "Common-Bile-Duct",
                "Decidua",
                "Ear",
                "Esophagus",
                "Eye",
                "Fallopian-Tube",
                "Gall-Bladder",
                "Heart",
                "Kidney",
                "Large-Intestine",
                "Liver",
                "Lung",
                "Lymph-Node",
                "Muscle",
                "Nose",
                "Ovary",
                "Pancreas",
                "Peritoneum",
                "Placenta",
                "Pleura",
                "Prostate",
                "Skin",
                "Small-Intestine",
                "Spleen",
                "Stomach",
                "Testis",
                "Thymus",
                "Thyroid",
                "Tonsil",
                "Ureter",
                "Uterus",
            ],
            pic_type: [
                { value: "Immune", label: "Immune" },
                { value: "Stromal", label: "Stromal" },
                { value: "Tissue-specific", label: "Tissue-specific" },
                { value: "Endothelial", label: "Endothelial" },
            ],
            ct_value: "",
            plt_show: false,
            mkData: [],
            immu_show_list: [],
            endo_show_list: [],
            stromal_show_list: [],
            ts_show_list: [],
            plt_url: "",
            clu: "",
            tmp: "",
            tmp_show: true,
            plt_show: false,
            tmp_show1: true,
            plt_show1: false,
            gene_name: "",
            show_gene_umap: false,


        };
    },
    change(e) {
        this.$forceUpdate();
    },
    created() {
        
        if (this.im.indexOf(this.input) > -1) {
            console.log(this.input)
            this.mid = false;
            this.radio='2'
            this.radio2='2'
            this.radio3='2'
            this.radio4='2'
            console.log('ok')
            console.log(this.mid)

        }
        if(this.im.indexOf(this.input) > -1 & this.endo.indexOf(this.input) > -1){
            this.radio='3'
            this.radio2='3'
            this.radio3='3'
            this.radio4='3'
        }
        if (this.endo.indexOf(this.input) > -1) {
            this.endo_show = false;

        }
        if (this.ts.indexOf(this.input) > -1) {
            this.ts_show = false;
            // this.endo_show_list.remove(2)
            // console.log(this.endo_show_list)
        }
        if (this.stromal.indexOf(this.input) > -1) {
            this.stromal_show = false;
        }
        this.marker = marker_json;
        this.TS = this.marker[this.input];
        for (var i in this.TS) {
            var obj = new Object();
            obj.celltype = i;
            obj.marker = this.TS[i];
            this.mkData.push(obj);
        }
        this.level1 = this.input;
        this.level3 = this.input;
        this.immune_url = 'static/' + this.level1 + '_Immune.html'
        this.endo_url = 'static/' + this.level1 + '_Endothelial.html'
        this.stromal_url = 'static/' + this.level1 + '_Stromal.html'
        this.ts_url = 'static/' + this.level1 + '_Tissue-specific.html'
        // console.log('test1')
        // this.endo_show_list=[require('@/assets/integrate/'+this.input+'_Endothelial.png'),require('@/assets/integrate/'+this.input+'_Stromal.png'),require('@/assets/integrate/'+this.input+'_Tissue-specific.png'),require('@/assets/integrate/'+this.input+'_Immune.png'),]
        // console.log('test2')
        // if (this.ts.indexOf(this.input) > -1) {
        //     this.endo_show_list.splice(2,1)
        //     console.log(this.endo_show_list)
        // }
        if (this.im.indexOf(this.input) > -1 & this.endo.indexOf(this.input) > -1) {
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]

        } else if (this.im.indexOf(this.input) > -1) {
            this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png')]
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
            this.immu_show_list=[]
            this.mid = false
    
        }
        else if (this.endo.indexOf(this.input) > -1 & this.ts.indexOf(this.input) > -1 & this.stromal.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png')]
        } else if (this.ts.indexOf(this.input) > -1 & this.stromal.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png')]
            this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Immune.png'),]
        } else if (this.endo.indexOf(this.input) > -1 & this.stromal.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]
        } else if (this.ts.indexOf(this.input) > -1 & this.endo.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]

        }
        else if (this.endo.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'),]
        } else if (this.stromal.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
            this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'),]

        } else if (this.ts.indexOf(this.input) > -1) {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
            this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'),]
        }else {
            this.immu_show_list = [require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
            this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png')]
            this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png')]
            this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Immune.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
        }
    // if (this.im.indexOf(this.input) > -1) {
    //         this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
    //         this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png')]
    //         this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
    //         this.immu_show_list=[]
    //         this.mid = false
    // }
    // if (this.im2.indexOf(this.input) > -1) {
    //         // this.endo_show_list = [require('@/assets/integrate/' + this.input + '_Endothelial.png'), require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
    //         this.stromal_show_list = [require('@/assets/integrate/' + this.input + '_Stromal.png'), require('@/assets/integrate/' + this.input + '_Tissue-specific.png')]
    //         this.ts_show_list = [require('@/assets/integrate/' + this.input + '_Tissue-specific.png'), require('@/assets/integrate/' + this.input + '_Stromal.png')]
    //         this.immu_show_list=[]
    //         this.mid = false
    //         this.
    // }
        this.CCI_Cluster = CCI_Cluster;
        this.Cluster_Immune = this.CCI_Cluster[this.level1 + '_Immune'];
        this.Cluster_Endotehlial = this.CCI_Cluster[this.level1 + '_Endotehlial'];
        this.Cluster_Stromal = this.CCI_Cluster[this.level1 + '_Stromal'];
        this.Cluster_TS = this.CCI_Cluster[this.level1 + '_Tissue-specific'];
        console.log()

        this.tmp = 0;
    },
    activated() {
        this.getconfigList() //页面访问接口获取数据的函数
    },
    computed: {},

    methods: {
        dimmu() {
            html2pdf(document.getElementById("png1"), {
                margin: 1,
                filename: "Immune.pdf",
            });
        },
        dendo() {
            html2pdf(document.getElementById("png2"), {
                margin: 1,
                filename: "Endothelial.pdf",
            });
        },
        dstro() {
            html2pdf(document.getElementById("png3"), {
                margin: 1,
                filename: "Stromal.pdf",
            });
        },
        dts() {
            html2pdf(document.getElementById("png4"), {
                margin: 1,
                filename: "Tissue-specific.pdf",
            });
        },
        dv() {
            html2pdf(document.getElementById("png5"), {
                margin: 1,
                filename: "Violin.pdf",
            });
        },
        dk1() {
            html2pdf(document.getElementById("png6"), {
                margin: 1,
                filename: "KEGG_UP.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dk2() {
            html2pdf(document.getElementById("png7"), {
                margin: 1,
                filename: "KEGG_DOWN.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dh1() {
            html2pdf(document.getElementById("png8"), {
                margin: 1,
                filename: "Hallmark_UP.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dh2() {
            html2pdf(document.getElementById("png9"), {
                margin: 1,
                filename: "Hallmark_Dwon.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dccih() {
            html2pdf(document.getElementById("png10"), {
                margin: 1,
                filename: "CCI_Heatmap.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dccic() {
            html2pdf(document.getElementById("png11"), {
                margin: 1,
                filename: "CCI_Circle.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dcciin() {
            html2pdf(document.getElementById("png12"), {
                margin: 1,
                filename: "CCI_in.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dcciout() {
            html2pdf(document.getElementById("png13"), {
                margin: 1,
                filename: "CCI_out.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dtfh() {
            html2pdf(document.getElementById("png14"), {
                margin: 1,
                filename: "TF_heat.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        dtfc() {
            html2pdf(document.getElementById("png15"), {
                margin: 1,
                filename: "TF_Rank.pdf",
                pagebreak: { mode: 'avoid-all' }
            });
        },
        async getViolin(gene, dataset, compare) {
            if (this.radio4 == 1) {
                this.gene_name = this.TissueName + '_Immune'
                console.log(this.gene_name)
            } else if (this.radio4 == 2) {
                this.gene_name = this.TissueName + '_Endothelial'
                console.log(this.gene_name)
            } else if (this.radio4 == 3) {
                this.gene_name = this.TissueName + '_Stromal'
                console.log(this.gene_name)
            } else if (this.radio4 == 4) {
                this.gene_name = this.TissueName + '_Tissue-specific'
                console.log(this.gene_name)
            }
            console.log('0')
            try {
                let loadingInstance = Loading.service({
                    target: document.getElementById("violin_plt"),
                    fullscreen: true
                });
                console.log('1')
                this.show_gene_violin = false;
                this.inputGene = this.inputGene.filter(item => {
                    return item.toUpperCase();
                });
                console.log('2')
                const response = await this.axios({
                    method: "post",
                    url: "violin_gene_local/",
                    data: {
                        gene: this.inputGene,
                        dataset: this.gene_name,
                        compare: this.compareType
                    },
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                console.log('3')
                this.img_viloin = response["data"];
                this.show_gene_violin = true;
                loadingInstance.close();
                console.log('4')
            } catch (err) {
                this.$alert("Please try again.", "Gene", {
                    confirmButtonText: "OK",
                    callback: action => {
                        this.$message({
                            type: "info",
                            message: `action: ${action}`
                        });

                    }
                });
            }
        },
        no_umap(){
            this.show_gene_umap=false
        },
        remoteMethod(query) {
            if (query !== "") {
                this.loading = false;
                setTimeout(() => {
                    this.loading = false;
                    this.optionss = this.list.filter(item => {
                        return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }, 200);
            } else {
                this.optionss = this.list.slice(0, 8);
            }
            console.log(this.optionss)
        },
        async getGene(dataset) {
            if (this.radio4 == 1) {
                this.gene_name = this.TissueName + '_Immune'
            } else if (this.radio4 == 2) {
                this.gene_name = this.TissueName + '_Endothelial'

            } else if (this.radio4 == 3) {
                this.gene_name = this.TissueName + '_Stromal'
            } else if (this.radio4 == 4) {
                this.gene_name = this.TissueName + '_Tissue-specific'
            }
            // console.log(this.immune)
            const response = await this.axios({
                method: "post",
                url: "get_gene/",
                data: {
                    dataset: this.gene_name
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.available_genes = response["data"];
            this.list = this.available_genes.map(item => {
                return { value: `${item}`, label: `${item}` };
            });
            // console.log(this.available_genes)
            return this.available_genes;
        },

        async searchGene(gene, dataset) {
            this.show_gene_umap = false;
            if (this.radio4 == 1) {
                this.gene_name = this.TissueName + '_Immune'
            } else if (this.radio4 == 2) {
                this.gene_name = this.TissueName + '_Endothelial'

            } else if (this.radio4 == 3) {
                this.gene_name = this.TissueName + '_Stromal'
            } else if (this.radio4 == 4) {
                this.gene_name = this.TissueName + '_Tissue-specific'
            }
            let loadingInstance = Loading.service({
                    target: document.getElementById("umap_plt"),
                    fullscreen: true
                });
            console.log(this.gene_name)
            this.inputGene = this.inputGene.filter(item => {
                return item.toUpperCase();
            });
            const response = await this.axios({
                method: "post",
                url: "detail_gene_local/",
                data: {
                    gene: this.inputGene,
                    dataset: this.gene_name,
                },
                headers: {
                    "Content-Type": "application/json"
                }
            });
            this.img = response["data"];
            this.show_gene_umap = true;
            this.umap_load = true;
            loadingInstance.close();
        },
        isShow() {
            this.show_gene_heatmap = false;
        },
        getPltUrl() {
            this.plt_url = "_C" + this.clu;
            this.tmp_show = false;
            this.plt_show = true;
        },
        getPltDot() {
            this.PltDot = "/C" + this.clu;
            this.tmp_show1 = false;
            this.plt_show1 = true;
        },
        async getHeatmap() {
            try {
                this.$refs["mark"].focus();
                const response = await this.axios({
                    method: "post",
                    url: "get_heatmap/",
                    data: {
                        gene: this.inputGene,
                        tissuename: this.input,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                this.img_heatmap = response["data"];
                this.show_gene_heatmap = true;
            } catch (err) {
                this.$alert(
                    "Please try again or check the input genes",
                    "Gene",
                    {
                        confirmButtonText: "OK",
                        callback: (action) => {
                            this.$message({
                                type: "info",
                                message: `action: ${action}`,
                            });
                        },
                    }
                );

            }
        },
        // getPltUrl() {
        //     this.plt_url = this.DatasetName + "_harmony_" + this.ct_value;
        //     this.plt_show = true;
        // },
        tableRowClassName({ row, rowIndex }) {
            row.index = rowIndex;
        },
        handleSizeChange(val) {
            this.currentPage = 1;
            this.currentPage = val;
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            this.currentPage = val;
        },
        onRowClick(row) {
            this.DatasetName = row.Dataset;
            this.$router.push(
                "/detail/" + this.DatasetName
            );
        },
    },
    mounted() {
        this.available_genes = this.getGene();
        this.optionss = this.list.slice(0, 8);
        console.log(this.optionss)

    }
};
</script>


<style lang="scss" >
@import "@/styles/info.scss";

#tiss {
    font-size: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#clu {
    display: flex;
    flex-direction: row;
}

#harmony {
    display: flex;
    flex-direction: column;
}

.el-card {
    margin-bottom: 30px;
}

#iframe {
    width: 800px;
    height: 800px;
}

#cc {
    display: flex;
    flex-direction: row;
}

#ccc {
    display: flex;
    flex-direction: row;
}

#download {
    display: flex;
    flex-direction: column;
    justify-content:center!important;
    // align-content: flex-end;

}

button {
    width: 150px;
    // border-style: none !important;;

}
#bt{
    font-size: 10px;
    color:rgb(26, 96, 67);
    margin:auto;
}
#png14{
    margin-left: 5%;
    margin-right: 5%;
}
#end{
    display: row;
    flex-direction: row;
    align-content: flex-end;
}
#sxy{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.scrollable {
    overflow-x: auto;
    overflow-y: hidden;
    height: 250px;
    white-space: nowrap;

    img {
        width: auto;
        height: 100%;
        margin-right: 10px;
    }
}
</style>